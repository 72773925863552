.App {
    text-align: center;

    a {
        cursor: pointer;
    }

    label {
        font-size: 1rem;
    }

    &-header {
        background-color: transparent;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        color: #282c34;

        &__public-logout-btn {
            display: block;
            position: absolute;
            top: 1rem;
            right: 1rem;
            opacity: 0;
        }
    }

    &-back-home-container {
        position: fixed;
        top: 1.5rem;
        left: 0;
        width: 100vw;
        font-size: 1.2rem;
    }
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-imgoing-buttons-container {
    padding: 1rem;

    & a {
        display: inline-block;
        margin: 1rem;
        width: 150px;
        height: 150px;
        font-size: 3rem;
        line-height: 150px;
        border-radius: 50%;
        color: #ffffff;

        &.button-in {
            background-color: #00b500;
        }

        &.button-out {
            background-color: #e92929;
        }
    }
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-link {
    color: #61dafb;
}

.App-back-home-container {
    font-size: 1.2rem;
    margin-top: 1.6rem;
    position: fixed;
    top: 0;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.ant-select {
    width: 100%;
}

.form-fields-container {
    text-align: left;
}

.form-footer {
    padding: 1rem;
    display: block;
    position: fixed;
    width: 100vw;
    bottom: 0;
    left: 0;
    background-color: #fefefe;
    border-top: 1px solid #f4f4f4;

    &__title {
        margin-bottom: 1rem;
    }

    &__buttons {
        button {
            margin: 1rem;
        }
    }

    &.big {
        button {
            font-size: 3rem;
            width: 150px;
            height: 150px;
            border-radius: 50% !important;
        }
    }
}

.visitor {

    &-in-form,
    &-out-form {
        background-color: #ffffff;
        box-sizing: border-box;
        width: 450px;
        padding: 2rem 3rem;
        box-shadow: 1px 2px 10px #dddddd;

        label {
            font-size: 1.2rem;
        }
    }
}

.privacy-drawer {

    p {
        font-size: 1rem;;
        line-height: 1.6rem;
    }
}