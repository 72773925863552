.login-page {
    background-color: #f4f4f4;
}

.login-form {
    background-color: #ffffff;
    box-sizing: border-box;
    width: 500px;
    padding: 3rem;
    box-shadow: 1px 2px 10px #dddddd;
}